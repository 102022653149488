/*----------------------------------------------------------------*/
/*  Print
/*----------------------------------------------------------------*/

@media all {

    /* Never show page breaks in normal view */
    .page-break-after,
    .page-break-before {
        display: none;
    }

}

@media print {

    /* html and body tweaks */
    html, body {
        height: auto !important;
        overflow: initial !important;
    }

    /* Page breaks */
    .page-break-after {
        display: block;
        page-break-after: always;
        position: relative;
    }

    .page-break-before {
        display: block;
        page-break-before: always;
        position: relative;
    }

    /* General styles */
    app-root {

        app-navbar-vertical,
        app-navbar-horizontal,
        app-toolbar,
        app-footer,
        app-quick-panel,
        app-theme-options,
        .ps > .ps__rail-x,
        .ps > .ps__rail-y {
            display: none !important;
        }

        .ps {
            overflow: visible !important;
        }

        .mat-drawer-container,
        .mat-sidenav-container {
            background-color: white !important;

            .mat-drawer-content,
            .mat-sidenav-content {
                overflow: initial !important;
                height: auto !important
            }
        }
    }
}